var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('el-row',[_c('el-col',{staticClass:"col",attrs:{"span":10,"xs":24}},[_c('div',{staticClass:"col_title"},[_vm._v("公告")]),_vm._l((_vm.notices),function(item){return _c('div',{key:item.id,staticClass:"notice"},[_c('img',{staticClass:"notice-img",attrs:{"src":item.banner,"alt":""}}),_c('div',{staticStyle:{"margin-left":"10px"}},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v(_vm._s(item.content))])])])})],2),_c('el-col',{staticClass:"col",attrs:{"span":14,"xs":24}},[_c('div',{staticClass:"col_title"},[_vm._v("住院信息")]),_c('el-table',{staticClass:"col_table",attrs:{"data":_vm.inHospitalData,"border":"","size":"mini","header-cell-style":{background:'#E4E7ED',color: '#333'}}},[_c('el-table-column',{attrs:{"prop":"hospitalized_time","label":"住院时间"}}),_c('el-table-column',{attrs:{"prop":"member.realname","label":"姓名"}}),_c('el-table-column',{attrs:{"prop":"hospital","label":"医院"}}),_c('el-table-column',{attrs:{"prop":"department","label":"科室"}}),_c('el-table-column',{attrs:{"prop":"number","label":"床位"}}),_c('el-table-column',{attrs:{"label":"住院状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.inHospitalStatusDict[row.status].label))])]}}])})],1)],1)],1),_c('el-row',[_c('el-col',{staticClass:"col",attrs:{"span":10,"xs":24}},[_c('div',{staticClass:"col_title"},[_vm._v("值班信息")]),_c('el-table',{staticClass:"col_table",attrs:{"data":_vm.dutyData,"border":"","size":"mini","header-cell-style":{background:'#E4E7ED',color: '#333'}}},[_c('el-table-column',{attrs:{"prop":"shift_time","label":"值班日期"}}),_c('el-table-column',{attrs:{"label":"值班员"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user ? row.user.name : '')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"角色"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.user)?[_vm._v(" "+_vm._s(row.user.roles ? row.user.roles[0].title : '')+" ")]:_vm._e()]}}])})],1)],1),_c('el-col',{staticClass:"col",attrs:{"span":14,"xs":24}},[_c('div',{staticClass:"col_title"},[_vm._v("派车信息")]),_c('el-table',{staticClass:"col_table",attrs:{"data":_vm.dispatchData,"border":"","size":"mini","header-cell-style":{background:'#E4E7ED',color: '#333'}}},[_c('el-table-column',{attrs:{"label":"车辆"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.car ? row.car.name : ''))]}}])}),_c('el-table-column',{attrs:{"prop":"dispatch_time","label":"派车时间"}}),_c('el-table-column',{attrs:{"prop":"applicant","label":"申请人"}}),_c('el-table-column',{attrs:{"prop":"apply_time","label":"申请时间"}}),_c('el-table-column',{attrs:{"prop":"target","label":"目的地"}}),_c('el-table-column',{attrs:{"prop":"return_time","label":"预计返回时间"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }