<template>
	<div class="main">
		<el-row>
			<el-col :span="10" :xs="24" class="col">
				<div class="col_title">公告</div>
				<div v-for="item in notices" :key="item.id" class="notice">
					<img :src="item.banner" alt="" class="notice-img">
					<div style="margin-left: 10px;">
						<h4>{{ item.title }}</h4>
						<p style="text-indent: 2em;">{{ item.content }}</p>
					</div>
				</div>
			</el-col>
			<el-col :span="14" :xs="24" class="col">
				<div class="col_title">住院信息</div>
				<el-table :data="inHospitalData" border size="mini"
					:header-cell-style="{background:'#E4E7ED',color: '#333'}" class="col_table">
					<el-table-column prop="hospitalized_time" label="住院时间"></el-table-column>
					<el-table-column prop="member.realname" label="姓名"></el-table-column>
					<el-table-column prop="hospital" label="医院"></el-table-column>
					<el-table-column prop="department" label="科室"></el-table-column>
					<el-table-column prop="number" label="床位"></el-table-column>
					<el-table-column label="住院状态" v-slot="{ row }">
						<span>{{ inHospitalStatusDict[row.status].label }}</span>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="10" :xs="24" class="col">
				<div class="col_title">值班信息</div>
				<el-table :data="dutyData" border size="mini" :header-cell-style="{background:'#E4E7ED',color: '#333'}"
					class="col_table">
					<el-table-column prop="shift_time" label="值班日期"></el-table-column>
					<el-table-column label="值班员" v-slot="{ row }">
						{{ row.user ? row.user.name : '' }}
					</el-table-column>
					<el-table-column label="角色" v-slot="{ row }">
						<template v-if="row.user">
							{{ row.user.roles ? row.user.roles[0].title : '' }}
						</template>
					</el-table-column>
				</el-table>
			</el-col>
			<el-col :span="14" :xs="24" class="col">
				<div class="col_title">派车信息</div>
				<el-table :data="dispatchData" border size="mini"
					:header-cell-style="{background:'#E4E7ED',color: '#333'}" class="col_table">
					<el-table-column label="车辆" v-slot="{ row }">{{ row.car ? row.car.name : '' }}</el-table-column>
					<el-table-column prop="dispatch_time" label="派车时间"></el-table-column>
					<el-table-column prop="applicant" label="申请人"></el-table-column>
					<el-table-column prop="apply_time" label="申请时间"></el-table-column>
					<el-table-column prop="target" label="目的地"></el-table-column>
					<el-table-column prop="return_time" label="预计返回时间"></el-table-column>
				</el-table>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		getStatisticsDataAPI
	} from '@/api/home.js'

	import {
		inHospitalStatusDict,
	} from '@/utils/dict.js'

	export default {
		name: 'index',
		data() {
			return {
				inHospitalStatusDict,

				notices: [],
				inHospitalData: [],
				dutyData: [],
				dispatchData: []
			}
		},
		created() {
			getStatisticsDataAPI().then(res => {
				this.notices = res.announcement
				this.dispatchData = res.carApplication
				this.dutyData = res.duty
				this.inHospitalData = res.hospitalization;
			})
		}
	}
</script>

<style scoped>
	.col {
		height: 41.5vh;
		padding: 0.5vh;
		overflow-y: auto;
	}

	.col_title {
		height: 5vh;
		line-height: 5vh;
		font-size: 18px;
		font-weight: 700;
	}

	.col_table {
		width: 100%;
		height: 35.5vh;
		overflow-y: auto;
	}

	.notice {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #EBEBEB;
		line-height: 1.5;
		padding: 30px 10px;
	}

	.notice:first-child {
		padding-top: 0;
		border: 1px solid red !important;
	}

	.notice-img {
		width: 15.625vw;
		height: auto;
	}
</style>